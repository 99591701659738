import { render, staticRenderFns } from "./header-logo-block.vue?vue&type=template&id=49ca0a2e"
import script from "./header-logo-block.vue?vue&type=script&lang=js"
export * from "./header-logo-block.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderBurger: require('/home/d/didisher2/autodrive.spb.ru/www/components/Header/header-burger.vue').default,HeaderLogo: require('/home/d/didisher2/autodrive.spb.ru/www/components/Header/header-logo.vue').default,HeaderSearch: require('/home/d/didisher2/autodrive.spb.ru/www/components/Header/header-search.vue').default})
