import { render, staticRenderFns } from "./modal-makes.vue?vue&type=template&id=c37c7fc0"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Makes: require('/home/d/didisher2/autodrive.spb.ru/www/components/makes/makes.vue').default})
