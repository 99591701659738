
import {mapGetters, mapMutations, mapActions} from 'vuex'
import bodyClasses from "@/mixins/bodyClasses";
import conditionPhone from "@/mixins/conditionPhone";
export default {
	mixins: [bodyClasses,conditionPhone],
	watch: {
		'$route'() {
			this.setModalMenu(false)
		},
	},
	data() {
		return {
			filterPopularMarks: null
		}
	},
	computed: {
		...mapGetters({
			modalMenu: 'modal/modal-menu/modalMenu',
			modalMarks: 'modal/modal-marks/modalMarks',
			modalSearch: 'modal/modal-search/modalSearch',
			likesArray: 'favorite/favorite/likesArray',
			settings: 'settings/settings/settings',
			componentCatalog: 'filters/filters/componentCatalog',
			markFolderGeneration: 'marks/marks/markFolderGeneration',
			popularMarks: 'popularMarks/popularMarks/popularMarks',
			folders: 'folders/folders/folders',
			backButton: 'header/header/backButton',
			mobileSearch: 'modal/modal-search/mobileSearch'
		}),
		currentMark() {
			return this.markFolderGeneration.filter(item => this.$route.params.mark === item.slug)[0]
		},
		currentFolder() {
			return this.folders.filter(item => this.$route.params.model === item.slug)[0]
		},
		showHeaderCrumbs() {
			if (this.$device.isMobile) {
				if (this.$route.params.car) {
					return this.componentCatalog === 'car';
				} else {
					return false
				}
			} else {
				return false
			}
		},
	},
	mounted() {
		this.getLikes()
	},
	methods: {
		...mapMutations({
			setModalMenu: 'modal/modal-menu/setModalMenu',
			setModalMarks: 'modal/modal-marks/setModalMarks',
			setModalSearch: 'modal/modal-search/setModalSearch',
			setMobileSearch: 'modal/modal-search/setMobileSearch'
		}),
		burgerClick() {
			this.setModalMenu(!this.modalMenu)
			this.setModalMarks(false)
			this.setModalSearch(false)
			this.setModalSearch(false)
		},
		navMarkClick() {
			this.setModalMenu(false)
			this.setModalMarks(false)
			this.setModalSearch(false)
		},
		allMarksClick() {
			this.setModalMarks(!this.modalMarks)
			this.setModalMenu(false)
			this.setModalSearch(false)
		},
		...mapActions({
			getLikes: 'favorite/favorite/getLikes',
			openModal: 'modal/modal-main/openModal'
		}),
	}
}
