import IsNy from "@/mixins/isNy";
import { timezoneOffsetCurrent } from "@/app/variables.js";
export default {
  mixins: [IsNy],
methods: {
    onPhone() {
        let dateObj = new Date();
        let timezoneOffset = -(dateObj.getTimezoneOffset() / 60);
        dateObj.setTime(dateObj.getTime() + ((timezoneOffsetCurrent - timezoneOffset) * 60 * 60 * 1000))
     
        let hours = dateObj.getHours();
        if(hours >= 21 || hours < 9 || this.isNewYearCheck){
            let payload = {
            modal_data: null,
            modal_component: 'modal-callback',
            modal_title: 'Обратный звонок',
            modal_sub_title: this.isNewYearCheck ? 'Автоцентр Автодрайв поздравляет вас с Новым годом! Оставьте заявку, и мы перезвоним вам 2 января!' : 'Мы работаем ' + this.settings.work_schedule.replace('Ежедневно ','') + '. Оставьте заявку, и мы свяжемся с вами в рабочее время!'
            }
            this.openModal(payload)
        }else{
            var myLink = document.createElement('a');
               myLink.setAttribute('href','tel:'+this.settings.phone.replace(/[^+\d]/g, ''));
               myLink.click();
        }
    },
},
}
