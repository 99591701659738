import { timezoneOffsetCurrent } from "@/app/variables.js";
export default {
  computed: {
    isNewYearCheck() {
      let dateObj = new Date();
      let timezoneOffset = -(dateObj.getTimezoneOffset() / 60);
      dateObj.setTime(dateObj.getTime() + ((timezoneOffsetCurrent - timezoneOffset) * 60 * 60 * 1000))
      let month = dateObj.getMonth() + 1; //months from 1-12
      let day = dateObj.getDate();
      let hours = dateObj.getHours();

      //проверка на дату с 31 декабря по 2 января
      if ((month === 12 && day === 31) || (month === 1 && day <= 2)) {
        if (
          //проверка на вермя с 18:00 31 ого
          (month === 12 && day === 31 && hours >= 18)
          ||
          //проверка на 1ое января
          (month === 1 && day === 1)
          ||
          //проверка на вермя с до 9:00 2ого
          (month === 1 && day === 2 && hours <= 9)) {
          return true;
        }
      }
      return false;
    },
  },
}
