import { render, staticRenderFns } from "./header.vue?vue&type=template&id=886d778a"
import script from "./header.vue?vue&type=script&lang=js"
export * from "./header.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderLogoBlock: require('/home/d/didisher2/autodrive.spb.ru/www/components/Header/header-logo-block.vue').default,SiteListDesktop: require('/home/d/didisher2/autodrive.spb.ru/www/components/site-list/site-list-desktop.vue').default,HeaderBackButton: require('/home/d/didisher2/autodrive.spb.ru/www/components/Header/header-back-button.vue').default,ModalMakes: require('/home/d/didisher2/autodrive.spb.ru/www/components/modal/modal-makes.vue').default,ModalMenu: require('/home/d/didisher2/autodrive.spb.ru/www/components/modal/modal-menu.vue').default,ModalSearch: require('/home/d/didisher2/autodrive.spb.ru/www/components/modal/modal-search.vue').default,Header: require('/home/d/didisher2/autodrive.spb.ru/www/components/Header/header.vue').default})
