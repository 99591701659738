
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters({
			isMoscow: 'isMoscow',
		}),
		siteList() {
			return [
				{
					title: 'Общие',
					links: [
						{
							name: 'Автомобили в наличии',
							link: '/cars',
							show: true
						},
					],
				},
				{
					title: 'Услуги',
					links: [
						{
							name: 'Автокредит',
							link: '/credit',
							show: true
						},
						{
							name: 'Trade-In',
							link: '/exchange',
							show: true
						},
						{
							name: 'Рассрочка',
							link: '/rassrochka',
							show: true
						},
						{
							name: 'Выкуп',
							link: '/buyout',
							show: true
						},
					]
				},
				{
					title: 'Информация',
					links: [
						{
							name: 'Контакты',
							link: '/contact',
							show: true
						},
						{
							name: 'Об автоцентре',
							link: '/about',
							show: true
						},
						{
							name: 'Отзывы',
							link: '/reviews',
							show: true
						},
						{
							name: 'Банки',
							link: '/banks',
							show: true
						},
					],
				},
			]
		}
	}
}
