
import {mapMutations} from "vuex";

export default {
	data() {
		return {
			nav: [
				{
					title: 'С пробегом',
					link: '/cars'
				},
				{
					title:'Автокредит',
					link:'/credit'
				},
				{
					title: 'Рассрочка',
					link: '/rassrochka',
				},
				{
					title:'Trade-In',
					link:'/exchange'
				},
				{
					title:'Выкуп',
					link:'/buyout'
				},
				{
					title:'Отзывы',
					link:'/reviews'
				},
				{
					title:'Банки',
					link:'/banks'
				},
				{
					title:'Контакты',
					link:'/contact'
				},
				{
					title:'Об автоцентре',
					link:'/about'
				},
			]
		}
	},
	methods: {
		...mapMutations({
			setModalMenu: 'modal/modal-menu/setModalMenu',
			setModalMarks: 'modal/modal-marks/setModalMarks',
			setModalSearch: 'modal/modal-search/setModalSearch',
		}),
		closeModals() {
			this.setModalMenu(false)
			this.setModalMarks(false)
			this.setModalSearch(false)
		}
	}
}
