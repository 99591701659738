
import {mapGetters, mapMutations} from "vuex";

export default {
	computed: {
		...mapGetters({
			searchResult: 'modal/modal-search/searchResult',
			modalSearch: 'modal/modal-search/modalSearch'
		})
	},
	methods: {
		...mapMutations({
			setModalSearch: 'modal/modal-search/setModalSearch',
			setMobileSearch: 'modal/modal-search/setMobileSearch',
			setModalMarks: 'modal/modal-marks/setModalMarks',
		}),
		searchClick() {
			this.setModalSearch('clear')
			this.setMobileSearch(false)
			this.setModalMarks(false)
		}
	}
}
