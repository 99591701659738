
import {mapGetters, mapMutations} from "vuex";

export default {
	data() {
		return {
			search: ''
		}
	},
	watch: {
		mobileSearch(){
			if(this.mobileSearch){
				this.$nextTick(() => this.$refs.search.focus())
			}
		},
		clearSearchValue() {
			this.search = ''
		}
	},
	computed: {
		...mapGetters({
			markFolderGeneration: 'marks/marks/markFolderGeneration',
			clearSearchValue: 'modal/modal-search/clearSearchValue',
			mobileSearch: 'modal/modal-search/mobileSearch'
		})
	},
	methods: {
		...mapMutations({
			setModalSearch: 'modal/modal-search/setModalSearch',
			setSearchResult: 'modal/modal-search/setSearchResult'
		}),
		clearSearch(){
			this.search = ''
			this.setModalSearch(false)
		},
		async onSearch() {
			let result = []
			result = this.markFolderGeneration.filter(mark => {
				return mark.title.toLowerCase().includes(this.search.toLowerCase())
			})
			await this.setSearchResult(result)
			if (this.search.length >= 3) {
				await this.setModalSearch(true)
			} else {
				await this.setModalSearch(false)
			}
		}
	}
}
