
export default {
	props: {
		forChoseModal: {
			type: Boolean,
			default: false
		},
		item: {
			type: Object,
			default: () => {
			}
		},
		active: {
			type: Boolean,
			default: false
		},
		slug: {
			type: String,
			default: ''
		}
	},
}
