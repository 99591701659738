
import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
	data() {
		return {
			stateHeightMakes: false,
		}
	},
	props: {
		forChoseModal: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapGetters({
			markFolderGeneration: 'marks/marks/markFolderGeneration',
			popularMarks: 'popularMarks/popularMarks/popularMarks',
			currentMark: 'modal/modal-choose/currentMark',
			modalMarks: 'modal/modal-marks/modalMarks',
		}),
		showAllButton(){
			if(this.$device.isMobile && this.markFolderGeneration){
				return this.markFolderGeneration.length > 10
			} else if(this.markFolderGeneration){
				return this.markFolderGeneration.length > 21
			}
		},
		// sortedMarks(){
		// 	return this.$_.sortBy(this.marksList, [function (mark) {
		// 		return mark.title;
		// 	}])
		// },
		computedStateHeightMakes(){
			if(this.stateHeightMakes == true){
                   return true;
			}else{
				return false;
			}
		},
		marksList() {
			let marks = [...this.markFolderGeneration]
			let count = 12;

			if(this.modalMarks){
                count = 16;
			}
			if(this.$route.name == 'Credit' || this.$route.name == 'Exchange'){
				count = 16;
		    }

			//если открыта модалка с хедера
			if(this.computedStateHeightMakes && this.$device.isMobile){
				// marks = marks.sort((a, b) => a.slug.toLowerCase().localeCompare(b.slug.toLowerCase()))
				// 	return marks;
				count = 16;
			}
			//если открыта модалка с хедера

			//поведение обычных марок
			 if(this.$device.isMobile){
				marks = marks.sort((a, b) => parseFloat(b.offers_count) - parseFloat(a.offers_count))
				if(!this.stateHeightMakes){
                     return marks.slice(0, count);
				}
				else{
					marks = marks.sort((a, b) => a.slug.toLowerCase().localeCompare(b.slug.toLowerCase()))
					return marks;
				}
			    }
				else{
					marks = marks.sort((a, b) => a.slug.toLowerCase().localeCompare(b.slug.toLowerCase()))
					return marks;
				}
						//поведение марок
		}
	},
	methods: {
		...mapActions({
			chooseMark: 'modal/modal-choose/chooseMark'
		}),
		...mapMutations({
			setModalSearch: 'modal/modal-search/setModalSearch',
			setModalMenu: 'modal/modal-menu/setModalMenu',
			setModalMarks: 'modal/modal-marks/setModalMarks'
		}),
		clickMarks() {
			this.setModalMarks(false)
			this.setModalSearch(false)
		},
		markClick(item) {
			this.setModalMarks(false)
			this.$router.push('/cars/' + item.slug)
		},
		setStateHeightMakes(){
			this.stateHeightMakes = !this.stateHeightMakes;
			if(this.stateHeightMakes == false){
				this.$refs.makes_list.scrollIntoView();
				window.scrollBy(0,-150);
			}
		}
	},
	mounted() {
		if(this.$route.name == 'Credit' || this.$route.name == 'Exchange'|| this.$route.name == 'Buyout' ){
			this.stateHeightMakes = false;
		}
	},
}
